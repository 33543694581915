import React from "react";
import {
  Spinner,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { ButtonFileViewer, ButtonErrorWarning, SingleDashboard } from "src/components/Common/Button";
import SeriesDashboard from "src/components/Common/Dropdown/SeriesDashboard";
import { PROCESS_STATUS, DASHBOARD_STATUS, RETRY_TIME, DASHBOARD_TYPE, SIMULATION_RESULT, DASHBOARD_URL_BY_TYPE, DASHBOARD_STATUS_BY_TYPE } from "src/constant/common";
import { extractIDsFromString } from "src/utils/common";
import { useSetRecoilState } from 'recoil';
import { alertState } from 'src/recoil/atoms/global';

import {
  getDashboardLink,
  getSensitivityDashboardLink,
  get6DofDashboardLink,
} from "src/services/simulator-flight";

const ThreeDofTable = ({ isLoading, isShow = true, isError, serieData, handleViewFile, simulationData, updateData }) => {
  const { t } = useTranslation(["flight-simulator", "common"]);
  const showAlert = useSetRecoilState(alertState);

  const handleUpdateSerieData = (index, field, newValue) => {
    const newData = { ...serieData };
    newData.data[index][field] = newValue;
    updateData(oldVal => ({
      ...oldVal,
      [simulationData.id]: newData
    }));
  };

  const getDashboardItemByType = (data, type) => {
    switch (type) {
      case DASHBOARD_TYPE.DISPERSION_ERROR:
      case DASHBOARD_TYPE.DISPERSION_RSS:
        return data?.simulation_dashboards?.filter(item => item.dashboard_type === type)?.[0] || null;
      default:
        return data;
    }
  }

  const isDispersionAnalysisDashboard = (type) => [DASHBOARD_TYPE.DISPERSION_ERROR, DASHBOARD_TYPE.DISPERSION_RSS].includes(type);

  const handleClickRenderDashboard = async (simulationData, serieItemData, serieIndex, type = DASHBOARD_TYPE.MAIN) => {
    const dashboardItem = getDashboardItemByType(serieItemData, type);
    if (!serieItemData) return;
    if (dashboardItem?.[DASHBOARD_STATUS_BY_TYPE[type]] === DASHBOARD_STATUS.SUCCEEDED && dashboardItem?.[DASHBOARD_URL_BY_TYPE[type]]) {
      window.open(dashboardItem[DASHBOARD_URL_BY_TYPE[type]], '_blank');
      document.body.click();
      return;
    }
    handleUpdateSerieData(serieIndex, 'loading', true);
    const [serieId, serieName] = extractIDsFromString(serieItemData.serie_file_name);
    let data = {
      id: simulationData.id,
      params: {
        serie_id: serieId,
        serie_name: serieName
      }
    }
    if (isDispersionAnalysisDashboard(type)) {
      data = {
        id: simulationData.id,
        input_file_id: serieId,
        dashboard_input_type: 'single',
        dashboard_type: type,
      }
    }
    await checkDashboardStatus(serieItemData, serieIndex, data, 1, type);
  };

  const checkDashboardStatus = async (serieItemData, serieIndex, params, retryCount, type) => {
    try {
      const res = await getDashboardResponse(type, params);
      if (await handleInProgressStatus(res, serieItemData, serieIndex, params, retryCount, type)) return;
      updateDashboardData(serieIndex, type, res.data);
      openDashboardIfSucceeded(res.data, type);
    } catch (e) {
      handleError(serieIndex, e);
    }
  }

  const getDashboardResponse = (type, params) => {
    switch (type) {
      case DASHBOARD_TYPE.SENSITIVITY:
        return getSensitivityDashboardLink(params);
      case DASHBOARD_TYPE.DISPERSION_RSS:
      case DASHBOARD_TYPE.DISPERSION_ERROR:
        return get6DofDashboardLink(params);
      default:
        return getDashboardLink(params);
    }
  }

  const handleInProgressStatus = async (res, serieItemData, serieIndex, params, retryCount, type) => {
    if (res.data.status !== DASHBOARD_STATUS.INPROGRESS) return false;
    if (retryCount === RETRY_TIME) {
      showAlert({ status: true, message: t("over_time"), type: 'danger' });
      handleUpdateSerieData(serieIndex, 'loading', false);
      return true;
    }
    await new Promise(resolve => setTimeout(resolve, 3000));
    await checkDashboardStatus(serieItemData, serieIndex, params, retryCount + 1, type);
    return true;
  }

  const updateDashboardData = (serieIndex, type, data) => {
    handleUpdateSerieData(serieIndex, 'loading', false);
    if (isDispersionAnalysisDashboard(type)) {
      const newData = { ...serieData?.data?.[serieIndex] || { simulation_dashboards: [] } };
      newData.simulation_dashboards.push(data);
      handleUpdateSerieData(serieIndex, 'simulation_dashboards', newData.simulation_dashboards);
    } else {
      handleUpdateSerieData(serieIndex, DASHBOARD_STATUS_BY_TYPE[type], data.status);
      handleUpdateSerieData(serieIndex, DASHBOARD_URL_BY_TYPE[type], data.url);
    }
  }

  const openDashboardIfSucceeded = (data, type) => {
    let statusField = 'status';
    let urlField = 'url';
    if (isDispersionAnalysisDashboard(type)) {
      statusField = DASHBOARD_STATUS_BY_TYPE[type];
      urlField = DASHBOARD_URL_BY_TYPE[type];
    }
    if (data[statusField] === DASHBOARD_STATUS.SUCCEEDED) {
      window.open(data[urlField], '_blank');
    }
  }

  const handleError = (serieIndex, e) => {
    handleUpdateSerieData(serieIndex, 'loading', false);
    showAlert({
      status: true,
      message: e?.response?.data?.error?.message || e?.response?.data?.message,
      type: 'danger',
    });
  }

  const isSensitivityAnalysisTrue = (obj, parentKey = '') => {
    if (typeof obj !== 'object' || obj === null) return false;
    return simulationData.execution_file_name === SIMULATION_RESULT.PAYLOAD && Object.keys(obj).some(key =>
      (typeof obj[key] === 'object' && isSensitivityAnalysisTrue(obj[key], key)) ||
      (parentKey === 'sensitivity_analysis' && key === 'is_sensitivity_analysis' && obj[key]?.value === 1)
    );
  };

  const isDispersionAnalysis = (obj, parentKey = '') => {
    if (typeof obj !== 'object' || obj === null) return false;
    return simulationData.execution_file_name === SIMULATION_RESULT.SINGLE && Object.keys(obj).some(key =>
      (typeof obj[key] === 'object' && isDispersionAnalysis(obj[key], key)) ||
      (parentKey === 'dispersion_analysis' && key === 'is_dispersion_analysis' && obj[key]?.value === 1)
    );
  };

  const isMultiDashboard = (jsonData) =>
    isSensitivityAnalysisTrue(jsonData) || isDispersionAnalysis(jsonData);

  return (
    isShow ? (
      isError ? (
        <div className="p-2 d-flex align-items-center justify-content-center w-100 height-by-px-70">
          {t("common:network_error")}
        </div>
      ) : isLoading ? (
        <div className="p-2 d-flex align-items-center justify-content-center w-100">
          <Spinner color="secondary" />
        </div>
      ) : (
        <div className="react-bootstrap-table table-detail overflow-auto">
          <table className="table">
            <thead className="table-secondary">
              <tr>
                <th className="width-by-px-80"></th>
                {serieData?.columns?.map((col, indexCol) => (
                  <th key={indexCol}>{col.text}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {
                serieData?.data?.map((serieRow, indexSerie) => (
                  <tr key={indexSerie}>
                    <td>
                      <div className="d-flex gap-2 align-items-center min-height-by-px-32">
                        <ButtonFileViewer handleClick={() => handleViewFile({ isOpen: true, serieData, fileData: serieRow })} id={`${simulationData.id}-${indexSerie}`} />
                        <ButtonErrorWarning isShow={serieRow?.errorList?.length > 0} errorContent={serieRow.errorList.join('\n')} id={`${simulationData.id}-${indexSerie}`} />
                        <SeriesDashboard
                          id={`${simulationData.id}-${indexSerie}`}
                          isLoading={serieRow?.loading}
                          isShow={(
                            simulationData.status === PROCESS_STATUS.SUCCEEDED &&
                            !serieRow?.errorList?.length &&
                            isMultiDashboard(serieRow.jsonObject)
                          )}
                          isSensitivityAnalysis={isSensitivityAnalysisTrue(serieRow.jsonObject)}
                          isDispersionAnalysis={isDispersionAnalysis(serieRow.jsonObject)}
                          serieData={serieRow}
                          getDashboardItemByType={getDashboardItemByType}
                          handleClickFlight={() => handleClickRenderDashboard(simulationData, serieRow, indexSerie, DASHBOARD_TYPE.MAIN)}
                          handleClickSensitivity={() => handleClickRenderDashboard(simulationData, serieRow, indexSerie, DASHBOARD_TYPE.SENSITIVITY)}
                          handleClickDispersionError={() => handleClickRenderDashboard(simulationData, serieRow, indexSerie, DASHBOARD_TYPE.DISPERSION_ERROR)}
                          handleClickDispersionRss={() => handleClickRenderDashboard(simulationData, serieRow, indexSerie, DASHBOARD_TYPE.DISPERSION_RSS)}
                        />
                        <SingleDashboard
                          id={`${simulationData.id}-${indexSerie}`}
                          isLoading={serieRow?.loading}
                          isShow={
                            simulationData.status === PROCESS_STATUS.SUCCEEDED &&
                            !serieRow?.errorList?.length &&
                            !isMultiDashboard(serieRow.jsonObject)
                          }
                          isReady={!!serieRow.dashboard_url}
                          handleClick={() => handleClickRenderDashboard(simulationData, serieRow, indexSerie, DASHBOARD_TYPE.MAIN)}
                        />
                      </div>
                    </td>

                    {serieData?.columns?.map((col, indexCol) => (
                      <td key={indexCol}>{serieRow.paramList[col.dataField]}</td>
                    ))}
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      )
    ) : null
  );
};

export default ThreeDofTable;